<template lang="">
  <div>
    <div>
      <b-row class="match-height">
        <b-col xl="4" md="4" sm="6">
          <statistic-card-vertical
            color="primary"
            icon="BoxIcon"
            :statistic="storageEntries.length"
            statistic-title="Lagereinheiten"
          />
        </b-col>
        <b-col xl="4" md="4" sm="6">
          <statistic-card-vertical
            color="success"
            icon="ShoppingCartIcon"
            :statistic="countOfRentedStorages().value"
            statistic-title="Vermietet"
          />
        </b-col>
        <b-col xl="4" md="4" sm="6">
          <statistic-card-vertical
            color="danger"
            icon="SlashIcon"
            :statistic="countOfNotRentedStorages().value"
            statistic-title="Nicht vermietet"
          />
        </b-col>
      </b-row>
    </div>

    <!-- Filters -->
    <b-card>
      <b-card-header class="pb-50">
        <h5>Filter</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="5" class="mb-md-0 mb-2">
            <!-- <label>Standort</label> -->
            <v-select
              v-model="locationFilter"
              placeholder="Standort"
              label="name"
              :options="$store.getters.locationsEntries"
              :clearable="false"
              @input="changedLocation"
            />
          </b-col>
          <b-col cols="12" md="5" class="mb-md-0 mb-2">
            <!-- <label>Vermietet</label> -->
            <v-select
              v-model="rentedFilter"
              placeholder="Vermietet"
              label="value"
              :options="isRented"
              :clearable="false"
              @input="changedisRented"
            />
          </b-col>
          <b-col>
            <b-button variant="primary" @click="clearFilter">
              Zurücksetzen
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- Per Page -->
            <label>Zeige</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Einträge</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6"
            ><div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filterCriteria[2]"
                class="d-inline-block mr-1"
                placeholder="Suche..."
                type="search"
              />
              <!--  -->
              <!-- Sidebar -->
              <!--  -->
              <b-button variant="primary" @click="createNewObject()">
                <span class="text-nowrap">Lager hinzufügen</span>
              </b-button>

              <b-sidebar
                id="sidebar-right"
                bg-variant="white"
                right
                backdrop
                shadow
                no-header
              >
                <sidebar-content />
              </b-sidebar>
              <b-sidebar
                id="sidebar-right-edit"
                bg-variant="white"
                right
                backdrop
                shadow
                no-header
              >
                <sidebar-content
                  :id="editStorageData.id"
                  :is-edit-mode="isEditMode"
                  :edit-storage-entry="editStorageData"
                />
              </b-sidebar>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-card no-body>
          <b-table
            ref="refListTable"
            striped
            hover
            responsive
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filterCriteria"
            :filter-function="filterTable"
            :items="storageEntries"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            show-empty
            empty-text="Keine Einträge gefunden"
            @row-clicked="onRowClicked"
            @filtered="onFiltered"
          >
            <template #cell(isStorageBooked)="{ item }">
              <b-badge v-if="isStorageBooked(item).value" variant="success">
                Ja
              </b-badge>
              <b-badge v-else variant="danger"> Nein </b-badge>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  :to="{ name: 'storages-view', params: { id: data.item.id } }"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Öffnen</span>
                </b-dropdown-item>

                <b-dropdown-item @click="editObject(data)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Bearbeiten</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteWarning(data)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Löschen</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von
                  {{ dataMeta.of }} Einträge</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="getTotalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BPagination,
  BBadge,
  BLink,
  BSidebar,
  VBToggle,
  BDropdown,
  BDropdownItem,
  BCardHeader,
  BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import storageHelper from '@/helper/storageHelper'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SidebarContent from './StorageListAddNew.vue'
import UsersListFilters from './StoragesListFilters.vue'
import tableHelper from '../../Table+Helper'

export default {
  setup() {
    const { isStorageBooked } = storageHelper()
    const { countOfRentedStorages } = storageHelper()
    const { countOfNotRentedStorages } = storageHelper()

    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      isStorageBooked,
      countOfRentedStorages,
      countOfNotRentedStorages,

      perPageOptions,
      filter,
      perPage,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    }
  },
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BPagination,
    BBadge,
    BLink,
    StatisticCardVertical,
    BSidebar,
    VBToggle,
    SidebarContent,
    BDropdown,
    BDropdownItem,
    UsersListFilters,
    vSelect,
    BCardHeader,
    BCardBody,
    ToastificationContent,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      storages: [],
      editStorageData: [],
      isEditMode: false,
      refListTable: null,
      sortBy: 'id',
      sortDesc: true,
      filterCriteria: [null, null, null],
      fields: [
        { key: 'storageIdent', label: 'Lager ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'isStorageBooked', label: 'Vermietet', sortable: true },
        // { key: 'storageSpace', label: 'Lagerfläche m²', sortable: true },
        // { key: 'storageVolume', label: 'Volumen m³', sortable: true },
        { key: 'storage_type.name', label: 'Typ', sortable: true },
        { key: 'location.name', label: 'Standort', sortable: true },
        { key: 'price', label: 'Preis', sortable: true },
        { key: 'actions', label: 'Aktionen' },
      ],
      isRented: [
        { key: true, value: 'Ja' },
        { key: false, value: 'Nein' },
      ],
      locationFilter: null,
      rentedFilter: null,
    }
  },
  computed: {
    storageEntries() {
      return this.$store.getters.storageEntries
    },
    storageTypes() {
      return this.$store.getters.getStorageTypes
    },
    totalStorageCount() {
      return this.$store.getters.storageEntries.length
    },
    getTotalRows() {
      return this.storageEntries.length
    },
    getLocationsEntries() {
      return this.$store.getters.locationsEntries
    },
  },
  mounted() {
    this.totalRows = this.getTotalRows
  },
  methods: {
    onRowClicked(item) {
      this.$router.push(`/storages-view/${item.id}`)
    },
    deleteWarning(data) {
      this.$swal({
        title: 'Bist du sicher?',
        // text: 'Das Lager wird unwiederruflich gelöscht.',
        text: `Das Lager "${data.item.name}" wirklich löschen?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Abbrechen',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch('deleteStorage', data.item.id)
            .then(() => {
              this.$store.dispatch('getStorageEntries')
              this.toastNotification(
                'Lager erfolgreich gelöscht.',
                'CheckIcon',
                'success'
              )
            })
            .catch(() => {
              this.toastNotification(
                'Lager konnte nicht gelöscht werden.',
                'AlertTriangleIcon',
                'danger'
              )
            })
        }
      })
    },

    toastNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },

    createNewObject() {
      if (this.getLocationsEntries.length === 0) {
        this.$swal({
          title: 'Standort erforderlich',
          text: 'Es muss mindestens ein Standort angelegt sein, bevor ein Lager erstellt werden kann.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        this.isEditMode = false
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      }
    },
    editObject(data) {
      this.editStorageData = data.item
      this.isEditMode = true
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
    },
    changedLocation(value) {
      this.filterCriteria.splice(0, 1, value.name)
    },
    changedisRented(value) {
      this.filterCriteria.splice(1, 2, value.key)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
    },

    clearFilter() {
      this.locationFilter = null
      this.rentedFilter = null
      this.filterCriteria = [null, null, null]
    },
    filterTable(row, filter) {
      // console.log(row)

      if (filter[0] === null && filter[1] === null && filter[2] === null) {
        return true
      }

      if (filter[2] != null) {
        if (row.name.toLowerCase().includes(filter[2].toLowerCase())) {
          return true
        }
        if (row.location.name.toLowerCase().includes(filter[2].toLowerCase())) {
          return true
        }
      } else if (filter[0] != null && filter[1] != null) {
        if (
          // eslint-disable-next-line operator-linebreak
          row.location.name === filter[0] &&
          this.isStorageBooked(row).value === filter[1]
        ) {
          return true
        }
      } else if (filter[0] != null) {
        if (row.location.name === filter[0]) {
          return true
        }
      } else if (this.isStorageBooked(row).value === filter[1]) {
        return true
      }

      return false
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
