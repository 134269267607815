var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Lager hinzufügen")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":_vm.closeSidebar}})],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{ref:"storageForm",staticClass:"p-2",on:{"submit":_vm.submitStorage}},[_c('b-form-group',{attrs:{"label":"Standort","label-for":"Standort"}},[_c('validation-provider',{attrs:{"name":"Standort","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isEditMode)?_c('v-select',{attrs:{"label":"name","options":_vm.$store.getters.locationsEntries,"state":errors.length > 0 ? false : null,"clearable":false},on:{"input":_vm.changedLocation},model:{value:(_vm.editStorageEntry.location),callback:function ($$v) {_vm.$set(_vm.editStorageEntry, "location", $$v)},expression:"editStorageEntry.location"}}):_c('v-select',{attrs:{"placeholder":"Standort auswählen...","label":"name","options":_vm.$store.getters.locationsEntries,"state":errors.length > 0 ? false : null,"clearable":false},on:{"input":_vm.changedLocation},model:{value:(_vm.storage.location),callback:function ($$v) {_vm.$set(_vm.storage, "location", $$v)},expression:"storage.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Lager ID","label-for":"Lager ID"}},[_c('b-input-group',{attrs:{"prepend":("" + _vm.prependLocationValue)}},[(_vm.isEditMode)?_c('b-form-input',{attrs:{"id":"storageIdent","disabled":""},model:{value:(this.editStorageEntry.storageID),callback:function ($$v) {_vm.$set(this.editStorageEntry, "storageID", $$v)},expression:"this.editStorageEntry.storageID"}},[_vm._v(" "+_vm._s(this.changedLocation())+" ")]):_c('b-form-input',{attrs:{"id":"storageIdent","disabled":""},model:{value:(this.storage.storageID),callback:function ($$v) {_vm.$set(this.storage, "storageID", $$v)},expression:"this.storage.storageID"}})],1)],1),_c('b-form-group',{attrs:{"label":"Lagertyp","label-for":"Lagertyp"}},[_c('validation-provider',{attrs:{"name":"Lagertyp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isEditMode)?_c('v-select',{attrs:{"label":"name","options":_vm.$store.getters.getStorageTypes,"clearable":false,"state":errors.length > 0 ? false : null},model:{value:(_vm.editStorageEntry.storage_type),callback:function ($$v) {_vm.$set(_vm.editStorageEntry, "storage_type", $$v)},expression:"editStorageEntry.storage_type"}}):_c('v-select',{attrs:{"placeholder":"Lagertyp auswählen...","label":"name","options":_vm.$store.getters.getStorageTypes,"clearable":false,"state":errors.length > 0 ? false : null},model:{value:(_vm.storage.storage_type),callback:function ($$v) {_vm.$set(_vm.storage, "storage_type", $$v)},expression:"storage.storage_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Zahlenschloss","label-for":"Zahlenschloss"}},[_c('validation-provider',{attrs:{"name":"Zahlenschloss","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isEditMode)?_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"autofocus":"","trim":""},model:{value:(_vm.editStorageEntry.lockPin),callback:function ($$v) {_vm.$set(_vm.editStorageEntry, "lockPin", $$v)},expression:"editStorageEntry.lockPin"}}):_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","placeholder":"1234"},model:{value:(_vm.storage.lockPin),callback:function ($$v) {_vm.$set(_vm.storage, "lockPin", $$v)},expression:"storage.lockPin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Name","label-for":"Name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isEditMode)?_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"autofocus":"","trim":""},model:{value:(_vm.editStorageEntry.name),callback:function ($$v) {_vm.$set(_vm.editStorageEntry, "name", $$v)},expression:"editStorageEntry.name"}}):_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","placeholder":"Container Basic"},model:{value:(_vm.storage.name),callback:function ($$v) {_vm.$set(_vm.storage, "name", $$v)},expression:"storage.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Lagerfläche m²","label-for":"Lagerfläche"}},[_c('validation-provider',{attrs:{"name":"Lagerfläche","rules":{ regex: /^\d*\.?\d*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isEditMode)?_c('b-form-input',{attrs:{"id":"storageSpace","state":errors.length > 0 ? false : null,"autofocus":"","trim":""},model:{value:(_vm.editStorageEntry.storageSpace),callback:function ($$v) {_vm.$set(_vm.editStorageEntry, "storageSpace", $$v)},expression:"editStorageEntry.storageSpace"}}):_c('b-form-input',{attrs:{"id":"storageSpace","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","placeholder":"18.65"},model:{value:(_vm.storage.storageSpace),callback:function ($$v) {_vm.$set(_vm.storage, "storageSpace", $$v)},expression:"storage.storageSpace"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Volumen m³","label-for":"Volumen"}},[_c('validation-provider',{attrs:{"name":"Volumen","rules":{ regex: /^\d*\.?\d*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isEditMode)?_c('b-form-input',{attrs:{"id":"storageVolume","autofocus":"","trim":""},model:{value:(_vm.editStorageEntry.storageVolume),callback:function ($$v) {_vm.$set(_vm.editStorageEntry, "storageVolume", $$v)},expression:"editStorageEntry.storageVolume"}}):_c('b-form-input',{attrs:{"id":"storageVolume","autofocus":"","trim":"","placeholder":"29.55"},model:{value:(_vm.storage.storageVolume),callback:function ($$v) {_vm.$set(_vm.storage, "storageVolume", $$v)},expression:"storage.storageVolume"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Preis €","label-for":"Preis"}},[_c('validation-provider',{attrs:{"name":"Preis","rules":{ regex: /^\d*\.?\d*$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isEditMode)?_c('b-form-input',{attrs:{"id":"price","autofocus":"","trim":""},model:{value:(_vm.editStorageEntry.price),callback:function ($$v) {_vm.$set(_vm.editStorageEntry, "price", $$v)},expression:"editStorageEntry.price"}}):_c('b-form-input',{attrs:{"id":"price","autofocus":"","trim":"","placeholder":"169"},model:{value:(_vm.storage.price),callback:function ($$v) {_vm.$set(_vm.storage, "price", $$v)},expression:"storage.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Beschreibung","label-for":"Beschreibung"}},[(_vm.isEditMode)?_c('b-form-textarea',{attrs:{"id":"description","rows":"3","autofocus":"","trim":""},model:{value:(_vm.editStorageEntry.description),callback:function ($$v) {_vm.$set(_vm.editStorageEntry, "description", $$v)},expression:"editStorageEntry.description"}}):_c('b-form-textarea',{attrs:{"id":"description","rows":"3","autofocus":"","trim":"","placeholder":"Beschreibung einfügen..."},model:{value:(_vm.storage.description),callback:function ($$v) {_vm.$set(_vm.storage, "description", $$v)},expression:"storage.description"}})],1),(!_vm.isEditMode)?_c('b-form-group',{attrs:{"label":"Massenerstellung"}},[_c('div',[_c('label',{staticClass:"mr-1",attrs:{"for":"sb-inline"}},[_vm._v("Anzahl der Container")]),_c('b-form-spinbutton',{attrs:{"id":"sb-inline","min":"1","inline":""},model:{value:(_vm.bulkCount),callback:function ($$v) {_vm.bulkCount=$$v},expression:"bulkCount"}})],1)]):_vm._e(),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[(_vm.isEditMode)?_c('div',[_vm._v("Aktualisieren")]):_c('div',[_vm._v("Hinzufügen")])]),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":_vm.closeSidebar}},[_vm._v(" Abbrechen ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }