<template>
  <div>
    <!-- Header -->

    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">Lager hinzufügen</h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="closeSidebar"
      />
    </div>
    <!-- BODY -->
    <!-- {{ editStorageEntry }} -->
    <validation-observer ref="simpleRules">
      <b-form ref="storageForm" class="p-2" @submit="submitStorage">
        <!-- Standort-->

        <b-form-group label="Standort" label-for="Standort">
          <validation-provider
            #default="{ errors }"
            name="Standort"
            rules="required"
          >
            <v-select
              v-if="isEditMode"
              v-model="editStorageEntry.location"
              label="name"
              :options="$store.getters.locationsEntries"
              :state="errors.length > 0 ? false : null"
              :clearable="false"
              @input="changedLocation"
            />

            <v-select
              v-else
              v-model="storage.location"
              placeholder="Standort auswählen..."
              label="name"
              :options="$store.getters.locationsEntries"
              :state="errors.length > 0 ? false : null"
              :clearable="false"
              @input="changedLocation"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Storage ID -->

        <b-form-group label="Lager ID" label-for="Lager ID">
          <b-input-group :prepend="`${prependLocationValue}`">
            <!-- <validation-provider
              #default="{ errors }"
              name="Lager ID"
              rules="required"
            > -->
            <b-form-input
              v-if="isEditMode"
              id="storageIdent"
              v-model="this.editStorageEntry.storageID"
              disabled
            >
              {{ this.changedLocation() }}
            </b-form-input>

            <b-form-input
              v-else
              id="storageIdent"
              v-model="this.storage.storageID"
              disabled
            />
            <!-- <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider> -->
          </b-input-group>
        </b-form-group>

        <!-- Lagertyp -->
        <b-form-group label="Lagertyp" label-for="Lagertyp">
          <validation-provider
            #default="{ errors }"
            name="Lagertyp"
            rules="required"
          >
            <v-select
              v-if="isEditMode"
              v-model="editStorageEntry.storage_type"
              label="name"
              :options="$store.getters.getStorageTypes"
              :clearable="false"
              :state="errors.length > 0 ? false : null"
            />
            <v-select
              v-else
              v-model="storage.storage_type"
              placeholder="Lagertyp auswählen..."
              label="name"
              :options="$store.getters.getStorageTypes"
              :clearable="false"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Zahlenschloss -->

        <b-form-group label="Zahlenschloss" label-for="Zahlenschloss">
          <validation-provider
            #default="{ errors }"
            name="Zahlenschloss"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              id="name"
              v-model="editStorageEntry.lockPin"
              :state="errors.length > 0 ? false : null"
              autofocus
              trim
            />
            <b-form-input
              v-else
              id="name"
              v-model="storage.lockPin"
              :state="errors.length > 0 ? false : null"
              autofocus
              trim
              placeholder="1234"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Name -->

        <b-form-group label="Name" label-for="Name">
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              id="name"
              v-model="editStorageEntry.name"
              :state="errors.length > 0 ? false : null"
              autofocus
              trim
            />
            <b-form-input
              v-else
              id="name"
              v-model="storage.name"
              :state="errors.length > 0 ? false : null"
              autofocus
              trim
              placeholder="Container Basic"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Lagerfläche -->
        <b-form-group label="Lagerfläche m²" label-for="Lagerfläche">
          <validation-provider
            #default="{ errors }"
            name="Lagerfläche"
            :rules="{ regex: /^\d*\.?\d*$/ }"
          >
            <b-form-input
              v-if="isEditMode"
              id="storageSpace"
              v-model="editStorageEntry.storageSpace"
              :state="errors.length > 0 ? false : null"
              autofocus
              trim
            />
            <b-form-input
              v-else
              id="storageSpace"
              v-model="storage.storageSpace"
              :state="errors.length > 0 ? false : null"
              autofocus
              trim
              placeholder="18.65"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Volumen-->
        <b-form-group label="Volumen m³" label-for="Volumen">
          <validation-provider
            #default="{ errors }"
            name="Volumen"
            :rules="{ regex: /^\d*\.?\d*$/ }"
          >
            <b-form-input
              v-if="isEditMode"
              id="storageVolume"
              v-model="editStorageEntry.storageVolume"
              autofocus
              trim
            />
            <b-form-input
              v-else
              id="storageVolume"
              v-model="storage.storageVolume"
              autofocus
              trim
              placeholder="29.55"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Preis-->
        <b-form-group label="Preis €" label-for="Preis">
          <validation-provider
            #default="{ errors }"
            name="Preis"
            :rules="{ regex: /^\d*\.?\d*$/ }"
          >
            <b-form-input
              v-if="isEditMode"
              id="price"
              v-model="editStorageEntry.price"
              autofocus
              trim
            />
            <b-form-input
              v-else
              id="price"
              v-model="storage.price"
              autofocus
              trim
              placeholder="169"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Beschreibung-->
        <b-form-group label="Beschreibung" label-for="Beschreibung">
          <b-form-textarea
            v-if="isEditMode"
            id="description"
            v-model="editStorageEntry.description"
            rows="3"
            autofocus
            trim
          />
          <b-form-textarea
            v-else
            id="description"
            v-model="storage.description"
            rows="3"
            autofocus
            trim
            placeholder="Beschreibung einfügen..."
          />
        </b-form-group>

        <b-form-group v-if="!isEditMode" label="Massenerstellung">
          <div>
            <label class="mr-1" for="sb-inline">Anzahl der Container</label>
            <b-form-spinbutton
              id="sb-inline"
              v-model="bulkCount"
              min="1"
              inline
            ></b-form-spinbutton>
          </div>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            @click.prevent="validationForm"
          >
            <div v-if="isEditMode">Aktualisieren</div>
            <div v-else>Hinzufügen</div>
          </b-button>

          <b-button
            type="button"
            variant="outline-secondary"
            @click="closeSidebar"
          >
            Abbrechen
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BInputGroup,
  BFormSpinbutton,
  BCollapse,
  BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BInputGroup,
    ToastificationContent,
    BFormSpinbutton,
    BCollapse,
    BCard,
  },
  props: ['id', 'isEditMode', 'editStorageEntry'],
  data() {
    return {
      storage: {
        name: '',
        storage_type: '',
        storageID: '',
        storageIdent: '',
        storageSpace: null,
        storageVolume: null,
        location: '',
        lockPin: '',
        price: null,
        description: '',
      },
      bulkCount: 1,
      prependLocationValue: '',
      required,
    }
  },
  computed: {
    storageTypes() {
      return this.$store.getters.getStorageTypes
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submitFormSuccess()
        }
      })
    },
    closeSidebar() {
      if (this.isEditMode) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
      } else {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      }
    },

    submitFormSuccess() {
      if (this.isEditMode) {
        this.submitEditStorage()
      } else {
        this.submitStorage()
      }
    },

    submitEditStorage() {
      this.$store
        .dispatch('updateStorage', this.editStorageEntry)
        .then(() => {
          console.log('updateStorage success')
          this.$store.dispatch('getStorageEntries')
          this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
          this.toastNotification('Lager aktualisiert', 'CheckIcon', 'success')
        })
        .catch(err => {
          console.error(err)
          this.toastNotification(
            'Lager konnte nicht aktualisiert werden',
            'AlertTriangleIcon',
            'danger'
          )
        })
    },

    async submitStorage() {
      // AddMode
      for (let i = 0; i < this.bulkCount; i += 1) {
        this.storage.storageID = this.getHighestStorageID()
        this.storage.storageIdent = this.getHighestStorageIdent()
        this.storage.name = this.storage.storageIdent

        console.log(JSON.stringify(this.storage))
        try {
          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch('addStorage', this.storage)
          console.log('addStorage success')
          // eslint-disable-next-line no-await-in-loop
          await this.$store.dispatch('getStorageEntries')

          // toggle sidebar if bulkCount is last iteration
          if (i === this.bulkCount - 1) {
            this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
          }

          this.toastNotification('Lager hinzugefügt', 'CheckIcon', 'success')
        } catch (err) {
          console.error(err)
          this.toastNotification(
            'Lager konnte nicht hinzugefügt werden',
            'AlertTriangleIcon',
            'danger'
          )
        }
      }
    },

    getHighestStorageID() {
      const highestID = this.$store.getters
        .getAllStorageFromLocationId(Number(this.storage.location.id))
        .reduce(
          // eslint-disable-next-line radix, no-confusing-arrow
          (max, obj) =>
            // eslint-disable-next-line radix
            parseInt(obj.storageID) > max ? parseInt(obj.storageID) : max,
          0
        )
        .toString()
        .padStart(4, '0')

      // plus 1 to highestID
      return (parseInt(highestID, 10) + 1).toString().padStart(4, '0')
    },

    getHighestStorageIdent() {
      return `${this.prependLocationValue}-${this.getHighestStorageID()}`
    },

    changedLocation() {
      if (this.isEditMode) {
        this.prependLocationValue = this.editStorageEntry.location.shortName
      } else {
        this.prependLocationValue = this.storage.location.shortName
        this.storage.storageID = this.getHighestStorageID()
        this.storage.storageIdent = this.getHighestStorageIdent()
      }
    },
    changedIdent(event) {
      if (this.isEditMode) {
        this.storage.storageIdent = `${this.prependLocationValue}-${event}`
      } else {
        this.storage.storageIdent = `${this.prependLocationValue}-${event}`
        this.storage.storageID = `${event}`
      }
    },
    toastNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
